import React, { useState, useEffect, useContext, useCallback } from "react";

import { NetworkContext } from "../context/NetworkContext";
import { ConnectContext } from "../context/ConnectContext";
import { Link } from "react-router-dom";

const Header = (props) => {
  const [error, setError] = useState();
  const [account, setAccount] = useContext(NetworkContext);
  const [provider, setProvider] = useContext(ConnectContext);
  
  


  var myDate = new Date();
  var hrs = myDate.getHours();

  var greet;
  if (hrs < 12) greet = "Good Morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";


  return (
    <>
    <div className="body-header sticky-md-top">
        <div className="container-fluid">
            <div className="d-flex justify-content-between">
            
            <a className="navbar-brand d-flex align-items-center color-900 ms-2" href="./" style={{width:'50%'}}>
                {/* <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-secondary" x="1.5" y="4.5" width="27" height="27" rx="13.5" />
                <rect className="fill-primary" x="13.5" y="1.5" width="33" height="33" rx="16.5" stroke="white" strokeWidth="3" />
                </svg>
                <span className="h4 mb-0 fw-bold ps-2">Uni-Merge</span> */}
                <img src="assets/img/logo.png" alt="" className="logoimg"/>
            </a>
            
            <div className="menu-link flex-fill">
                
                <div className="dropdown menu-apps active">
                <Link to="./" className="btn btn-link dropdown-toggle after-none" >
                    <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="18" fill="currentColor" viewBox="0 0 16 16">
                    <path className="fill-secondary" d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"></path>
                    <path className="fill-muted" fillRule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"></path>
                    </svg>
                    <span>Home</span>
                </Link>
                </div>
                
                <div className="dropdown menu-apps">
                <Link to="./launchpad" className="btn btn-link dropdown-toggle after-none" >
                    <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="18" fill="currentColor" viewBox="0 0 16 16">
                    <path className="fill-muted" d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"></path>
                    <path className="fill-secondary" d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
                    </svg>
                    <span>Launchpad</span>
                </Link>
                
                </div>
                
                
                
                <div className="dropdown menu-resources">
                <Link to="./swap" className="btn btn-link dropdown-toggle after-none" >
                    <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="18" fill="currentColor" viewBox="0 0 16 16">
                    <path className="fill-secondary" d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path>
                    <path className="fill-muted" d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"></path>
                    <path className="fill-muted" d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"></path>
                    </svg>
                    <span>Docs</span>
                </Link>
                
                </div>
            </div>
            
            <ul className="nav navbar-right d-flex align-items-center mb-0 list-unstyled">
                
           
                <li>
                <div className=" morphing scale-right  ">
                    <div className="nav-link  rounded-circle after-none p-0" >
                        <span className="btn btn-sm btn-outline-info rounded">Connect</span>
                    </div>
                </div>
                </li>
                <li>
                <div className="dropdown morphing scale-left user-profile mx-lg-3 mx-2">
                    <div className="nav-link  rounded-circle after-none p-0" >
                        <img className="avatar md  rounded-circle shadow" src="assets/img/bnb.webp" alt=""/>
                    </div>
                    
                </div>
                </li>
                
                
            </ul>
            </div>
        </div>
    </div>
    </>
  );
};

export default Header;