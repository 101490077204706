import React from "react";
import { Link } from 'react-router-dom';
const Launchpad = () => {
    return (
      <>
        {/* <div className="page-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="h2 mb-md-0 text-white fw-light">Uni-Merge</h1>
                            <div className="page-action">
                                <h1 className="h2 mb-md-0 text-white fw-light">dApp</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div >
            <div className="container-fluid mt-4">
                <div className="row g-3 justify-content-xl-between">
                    <div className="col-md-8 offset-lg-2">
                        <div className="card p-4 mb-3">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <button type="button" className="btn btn-outline-info">Live[0]</button>
                                <button type="button" className="btn btn-outline-info">Upcomming[1]</button>
                                <button type="button" className="btn btn-outline-info">Ended[0]</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div >
            <div className="container-fluid mt-2">
                <div className="row g-3 justify-content-xl-between">
                    <div className="col-md-4 ">
                        <div className="card p-4 mb-3 ribbon ">
                            <div className="option-3 bg-danger position-absolute text-light" style={{fontSize : "10px"}}>Upcomming</div>
                            <div className="card-header justify-content-center p-1">
                                <h6 className="">UniMerge Seed Round</h6>
                            </div>
                            <div className="card-body">
                                <div> Price </div>
                                <p>1 UNMG = 0.25 USDT / 1 BUSD = 4.00 UNMG</p>
                                <div> Soft/Hard Cap </div>
                                <p>0 UNMG - 400,000 UNMG</p>
                                <div> Time </div>
                                <p>2022.04.12 09.00 AM UTC</p>
                                <div> Progress </div>
                                <div className="progress mt-2" style={{height: '15px'}}>
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '44%'}} aria-valuenow="44" aria-valuemin="0" aria-valuemax="100">
                                        20000 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="text-center  mt-3">
                            <Link to='/seed' className="btn btn-outline-info">Details</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 ">
                        <div className="card p-4 mb-3 ribbon ">
                            <div className="option-3 bg-danger position-absolute text-light" style={{fontSize : "10px"}}>Upcomming</div>
                            <div className="card-header justify-content-center p-1">
                                <h6 className="">UniMerge Private Round</h6>
                            </div>
                            <div className="card-body">
                                <div> Price </div>
                                <p>1 UNMG = 0.25 USDT / 1 BUSD = 4.00 UNMG</p>
                                <div> Soft/Hard Cap </div>
                                <p>0 UNMG - 400,000 UNMG</p>
                                <div> Time </div>
                                <p>2022.04.12 09.00 AM UTC</p>
                                <div> Progress </div>
                                <div className="progress mt-2" style={{height: '15px'}}>
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '44%'}} aria-valuenow="44" aria-valuemin="0" aria-valuemax="100">
                                        20000 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="text-center  mt-3">
                                <button className="btn btn-outline-info">Details</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 ">
                        <div className="card p-4 mb-3 ribbon ">
                            <div className="option-3 bg-danger position-absolute text-light" style={{fontSize : "10px"}}>Upcomming</div>
                            <div className="card-header justify-content-center p-1">
                                <h6 className="">UniMerge Public Round</h6>
                            </div>
                            <div className="card-body">
                                <div> Price </div>
                                <p>1 UNMG = 0.25 USDT / 1 BUSD = 4.00 UNMG</p>
                                <div> Soft/Hard Cap </div>
                                <p>0 UNMG - 400,000 UNMG</p>
                                <div> Time </div>
                                <p>2022.04.12 09.00 AM UTC</p>
                                <div> Progress </div>
                                <div className="progress mt-2" style={{height: '15px'}}>
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '44%'}} aria-valuenow="44" aria-valuemin="0" aria-valuemax="100">
                                        20000 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="text-center  mt-3">
                                <button className="btn btn-outline-info">Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  };
  
  export default Launchpad;