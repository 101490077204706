import { NetworkProvider } from "./context/NetworkContext";
import { ConnectProvider } from "./context/ConnectContext";
import {Helmet} from "react-helmet";
import {BrowserRouter, Route, Routes } from "react-router-dom";
import { useContext, useState } from 'react';
import './App.css';
import Home from "./pages/home";
import Seed from "./pages/seed";
import Launchpad from "./pages/launcpad";
import Comming from "./pages/comming";
import Header  from "./components/Header";
function App() {
const [theam , setTheam ] =useState('dark')


return (
  
    
<BrowserRouter>
    <ConnectProvider>
      <NetworkProvider>
      
      <div className="avio" data-theme="light">
      <Header/>
          <Routes>
              <Route path="/seed" element={<Seed />} />
              <Route path="/launchpad" element={<Launchpad />} />
              <Route path="/Private" element={<Comming />} />
              <Route path="/Public" element={<Comming />} />
              <Route path="/Airdrop" element={<Comming />} />
              <Route path="/swap" element={<Comming />} />
              <Route path="/pool" element={<Comming />} />
              <Route path="/" element={<Launchpad />} />
          </Routes>
          
        </div>
        
      </NetworkProvider>
    </ConnectProvider>
  </BrowserRouter>
  
  
);
 
}

export default App;
