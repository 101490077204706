import React from "react";
import { Link } from "react-router-dom";

const Seed = () => {
    return (
      <>
        <div >
            <div className="container-fluid mt-2 p-4">
                <div className="row g-3 justify-content-xl-between">
                    <div className="col-md-5 ">
                        <div className="card p-4 mb-3 ribbon ">
                            <div className="option-3 bg-info position-absolute text-light" style={{fontSize : "10px"}}>Live</div>
                            <div className=" text-center">
                                <img className="avatar lg  rounded-circle shadow alinge-item-center" src="assets/img/icon_b.png" alt=""/>
                             </div>
                            <div className="card-body ">
                                <div className="text-center">
                                <h5 className="alinge-item-end"> Seed Round</h5>
                                </div>
                                <div> Time </div>
                                <p>2022.04.12 09.00 AM UTC</p>
                                <div> Progress </div>
                                <div className="progress mt-2" style={{height: '15px'}}>
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '44%'}} aria-valuenow="44" aria-valuemin="0" aria-valuemax="100">
                                        20000 USDT
                                    </div>
                                </div>
                                <div className="mt-3"> My Investment </div>
                                <p>0 USDT</p>
                                <div> Available UNMG </div>
                                <p>0 UNMG </p>
                                <label>USDT</label>
                                <div className="input-group mb-3 ">
                                    <input type="number" className="form-control" />
                                    <div className="input-group-append">
                                        <span className="input-group-text">MAX</span>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center  mt-3">
                                <button type="button" className="btn btn-outline-info btn-sm">Approv</button>
                                <button type="button" className="btn btn-outline-info btn-sm ms-2">Deposite</button>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-md-7 ">
                        <div className="card p-4 mb-3 ribbon ">
                        <div className=" text-center">
                                <img className="avatar lg  rounded-circle shadow alinge-item-center" src="assets/img/icon_b.png" alt=""/>
                             </div>
                            <div className="card-body">
                            <div className="text-center">
                                <h5 className="alinge-item-end"> UniMerge</h5>
                                </div>
                                <p> At Uni-Merge, we're not just another fintech - we're a fun, dynamic team of self-driven individuals who are passionate about making an impact in the payments industry. We're looking for talented individuals who are committed to excellence and are ready to join us on our mission to revolutionize the way businesses make payments. </p>
                                <div className="row">
                                    <div className="col-5">
                                        <b>Soft Cap</b>
                                        <p>2000 USDT</p>
                                    </div>
                                    <div className="col-5">
                                        <b>Hard Cap</b>
                                        <p>200000 USDT</p>
                                    </div>
                                </div>
                                <h6>Supported Coin</h6>
                                <p>1 UNMG = 0.25 USDT / 1 BUSD = 4.00 UNMG</p>
                                <h6> Token Address </h6>
                                <Link style={{color:'white'}} to="https://bscscan.com/address/0xaF027427DC6d31A3e7e162A710a5Fe27e63E275F" target="_blank">0xaF027427DC6d31A3e7e162A710a5Fe27e63E275F</Link>
                                <h6>Allocation </h6>
                                <p>Min 10 MAX 2500</p>
                                <div> Progress </div>
                                <div className="progress mt-2" style={{height: '15px'}}>
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '44%'}} aria-valuenow="44" aria-valuemin="0" aria-valuemax="100">
                                        20000 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="text-center  mt-3">
                                <button className="btn btn-outline-info">Details</button>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
      </>
    );
  };
  
  export default Seed;